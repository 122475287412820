import * as React from 'react';

import Button from '../components/Button';
import Link from 'gatsby-link';
import { MyTheme } from 'src/types/types';
import injectSheet from 'react-jss';

const PrivacyPolicyPage = ({ classes }: any) => {
    return (
        <div className={classes && classes.container}>
            <h1>Vortexa Privacy Policy</h1>
            <ol>
                <li>
                    <h2>Introduction</h2>
                    <p>
                        Thank you for choosing the Vortexa Add-in. This Privacy Policy contains a policy statement
                        regarding the collection, use and processing of personal data, with whom we may share such data
                        and your rights in relation to personal data. This Policy applies to information we collect when
                        you use our excel add-in.
                    </p>
                    <p>
                        We may change the provisions of this Policy at any time and will indicate when changes have been
                        made by revising the date at the top of this Policy. We encourage you to review the Policy
                        whenever you access the Services to make sure that you understand our information collection,
                        use and disclosure practices. If we make material changes to this policy, we will provide you
                        with additional notice of such changes and request your affirmative consent before using or
                        sharing previously collected information in a materially different way.
                    </p>
                </li>
                <li>
                    <h2>Personal data we collect</h2>
                    <p>We may process the following personal data in connection with the Service:</p>

                    <p>Registration data </p>

                    <p>
                        When you log in the Service, we will ask you for personal data such as your user name, password.
                        Please note that we are unable to provide the Service unless you provide the above stated
                        personal data.
                    </p>

                    <h3>Usage information </h3>

                    <p>
                        When you use or interact with the Service, we will use a variety of technologies that collect
                        information about how the Service is accessed. This personal data includes (i) information about
                        your interactions with the Service; (ii) content that you post to the Service; and (iii)
                        technical data, which may include URL information, cookie data, your IP address and the types
                        and identity of devices and network you are using to access or connect to the Service. Vortexa
                        may collect automated error reports in the case of software malfunction; such error reports may
                        contain some or all of the information in your documents and may be reviewed to help resolve
                        problems with the Vortexa software or service.
                    </p>
                </li>
                <li>
                    <h2>How we use the personal data we collect</h2>
                    <p>We will process the personal data set out above for the following purposes:</p>
                    <p>
                        <ul>
                            <li>
                                to administrate your account, to enable and provide the Service including, as
                                applicable, integration with third party services, and to provide, personalize and
                                improve your experience with the Service and any.This processing is necessary for the
                                fulfilment of the contract we have with you
                            </li>
                            <li>
                                to ensure technical functionality of the Service, develop new products and services, and
                                analyze your use of the Service. This processing is based on our legitimate interest to
                                ensure that our Service is of continuously high quality and to better understand how our
                                Service is used to improve it even further.
                            </li>
                            <li>
                                to communicate with you for Service-related or research purposes including via emails,
                                notifications, text messages, or other messages. This processing is lawful on grounds of
                                our legitimate interest to interact with you, to inform you about Service-related
                                changes or to invite you to participate in researches about the Service
                            </li>
                            <li>
                                to communicate with you, either directly or through one of our partners, for marketing
                                purposes via emails, notifications, or other messages, consistent with any permissions
                                you may have communicated to us. This processing is based on our legitimate interest to
                                send you promotional material. You have the absolute right to opt-out of the use of your
                                personal data for this purpose.
                            </li>
                            <li>
                                to enable and promote the Service and other services or products, either within or
                                outside the Service;
                            </li>
                            <li>
                                to enforce this Privacy Policy, the Terms of Use, and any other terms that you have
                                agreed to, including to protect the rights, property, or safety of Vortexa, its users,
                                or any other person, or the copyright-protected content of the Service. This processing
                                based on our legitimate interest to ensure that the terms under which you make use of
                                our Service are enforced. as otherwise stated in this Privacy Policy.
                            </li>
                        </ul>
                    </p>
                </li>

                <li>
                    <h2>How we share personal data</h2>
                    <p>We share your personal data with third parties for these limited purposes:</p>
                    <h3>When we have your consent.</h3>
                    <p>
                        With third party vendors, consultants and other service providers who are working on our behalf,
                        who are hosting our data, and need access to your information to carry out their work for us.
                        These entities have agreed to maintain the confidentiality, security, and integrity of the
                        personal information they obtain from us, and, unless we notify you otherwise and provide you
                        with an opportunity to opt-out, will not use your personal information for any purpose other
                        than as described in this Policy.
                    </p>
                    <p>
                        With data analytics in order to understand to get a better understanding of how people use our
                        Service
                    </p>
                    <p>
                        With law enforcement, courts of competent jurisdiction, or others when we have a good faith
                        belief that access, use, preservation or disclosure of such information is reasonably necessary
                        to (a) satisfy any applicable law, regulation, legal process or enforceable governmental
                        request, (b) enforce applicable Terms of Service, including investigation of potential
                        violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical
                        issues, or (d) protect against harm to the rights, property or safety of Vortexa, its users or
                        the public as required or permitted by law.
                    </p>
                    <p>
                        We may share with third parties certain pieces of aggregated, non-personal information, such as
                        the number of users who used a type of document, for example, or how many users clicked on a
                        particular advertisement. Such information does not identify you individually.
                    </p>
                    <p>
                        In connection with, or during negotiations of, any merger, sale of some or all of Vortexa’s
                        assets, bankruptcy or reorganization, financing or acquisition of all or a portion of Vortexa’s
                        business to another company.
                    </p>
                </li>
                <li>
                    <h2>Data Security</h2>
                    <p>
                        Vortexa takes reasonable measures to protect your personal information and your documents from
                        loss, misuse and unauthorized access, disclosure, alteration and destruction and to ensure that
                        your documents remain available to you.
                    </p>
                </li>

                <li>
                    <h2>Data Retention</h2>
                    <p>
                        We store the information we collect about you for as long as is necessary for the purpose(s) for
                        which we originally collected it. We may retain certain information for legitimate business
                        purposes or as required by law.
                    </p>
                </li>
                <li>
                    <h2>Data Storage</h2>
                    <p>
                        By submitting Your personal data, You agree to this transfer, storing or processing. We will
                        take all steps reasonably necessary to ensure that Your data is treated securely and in
                        accordance with this Privacy Policy.
                    </p>
                    <p>All information You provide to Us is stored on secure servers following GDPR guidelines.</p>
                </li>

                <li>
                    <h2>Children's Policy</h2>
                    <p>
                        The Children’s Online Privacy Protection Act was created to protect children under the age of 13
                        from unsuspecting acts or practices in conjunction with collecting, using, and disclosing any
                        information about them. Our Site is not intended for anyone under the age of 13. If you are
                        under 13, do not use or provide any information on or through our Site. If we learn we have
                        collected or received personal information from a child under 13 without verification of
                        parental consent, we will delete that information. If you are a parent or guardian or otherwise
                        believe we might have any information from or about a child under 13, please contact us so that
                        we can delete the child's information. The Site will never knowingly accept, collect, maintain
                        or use any information from a child under the age of 13. If a child whom we know to be under the
                        age of 13 sends personal information to us online, we will only use that information to respond
                        directly to that child or notify parents.
                    </p>
                </li>
                <li>
                    <h2>Your Choices</h2>
                    <p>You may terminate your use of Vortexa at any time.</p>
                    <p>
                        You can review, update, and change some of your personal information – including your email
                        address and password – in your profile at any time. We will allow you access to your personal
                        information and allow you to correct, amend, or delete inaccurate information. For access
                        requests please contact info@vortexa,com.{' '}
                    </p>
                </li>
                <li>
                    <h2>Changes to the Privacy Policy</h2>
                    <p>
                        We may change the provisions of this Policy at any time and will indicate when changes have been
                        made by revising the date at the top of this Policy. We encourage you to review the Policy
                        whenever you access the Services to make sure that you understand our information collection,
                        use and disclosure practices. If we make material changes to this policy, we will provide you
                        with additional notice of such changes and request your affirmative consent before using or
                        sharing previously collected information in a materially different way.
                    </p>
                </li>
            </ol>
            <Button>
                <Link to="/">Back</Link>
            </Button>
        </div>
    );
};

const styles = (_theme: MyTheme) => ({
    container: {
        height: '93vh',
        margin: '0 auto',
        padding: '5% 10%'
    }
});

export default injectSheet(styles)(PrivacyPolicyPage);
